<template>
  <SdPage>
    <SdLoadingLayer
      ref="loadingLayer"
      :init="init"
    >
      <div slot="loaded">
        <div class="row justify-content-center">
          <div class="col-12 text-center mt-3">
            <div class="font-21 font-weight-strong">
              Ready to start showing this property?
            </div>
            <div class="font-17 mt-1">
              Please select the type of showing
            </div>
          </div>
          <div class="col-12 col-md-7">
            <div class="row">
              <div :class="{'col-md-12': !uiFlags.isSelfShowingEnabled, 'col-md-6': uiFlags.isSelfShowingEnabled}">
                <div
                  class="showing-type-card-pick"
                >
                  <div
                    class="content"
                  >
                    <div class="art-agent-showing">
                      <SdArt
                        class="art"
                        art="showing"
                        size="80px"
                      />
                    </div>
                    <div class="title font-weight-strong my-3">
                      Agent Showing
                    </div>
                    <div
                      class="choose-button"
                    >
                      <ElButton
                        class="w-100"
                        type="primary"
                        size="medium"
                        @click="pickShowing(Showing.TYPE_AGENT_SHOWING)"
                      >
                        Choose
                      </ElButton>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="uiFlags.isSelfShowingEnabled"
                class="col-md-6"
              >
                <div
                  class="showing-type-card-pick"
                >
                  <div
                    class="content"
                  >
                    <div class="art-self-showing">
                      <SdArt
                        class="art"
                        art="lockbox"
                        size="80px"
                      />
                    </div>
                    <div class="title font-weight-strong my-3">
                      Self Showing
                    </div>
                    <div
                      v-if="uiFlags.codeBoxOptionUnavailable"
                      class="mb-3"
                    >
                      <div
                        v-if="uiFlags.allInUse"
                      >
                        All of your currently registered devices are already in use
                      </div>
                    </div>
                    <div
                      class="choose-button"
                    >
                      <ElButton
                        class="w-100"
                        type="primary"
                        size="medium"
                        @click="pickShowing(Showing.TYPE_SELF_SHOWING)"
                      >
                        Choose
                      </ElButton>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-center">
                <router-link
                  v-if="uiFlags.isPublishListingEnabled"
                  :to="addPublishListingShowingProfileRoute"
                >
                  or, publish the listing and allow a third-party to coordinate tours
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SdLoadingLayer>
  </SdPage>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import { DEVICE_STATUS } from '@/constants/Device';
import get from 'lodash.get';
import { SHOWING_PROFILE_ROUTES } from '@/router/routes';
import Showing from '@/constants/Showing';
import { isBusinessSelfShowingsEnabled } from '@/utils/BusinessUtil';
import SdLoadingLayer from '@/components/common/SdLoadingLayer.vue';
import SdPage from '@/components/common/layout/SdPage.vue';

const showingRoutesMapping = {
  [Showing.TYPE_SELF_SHOWING]: SHOWING_PROFILE_ROUTES.SELF_SHOWING_PAGE,
  [Showing.TYPE_AGENT_SHOWING]: SHOWING_PROFILE_ROUTES.AGENT_SHOWING_PAGE,
  [Showing.TYPE_PUBLISH_LISTING]: SHOWING_PROFILE_ROUTES.PUBLISH_LISTING_SHOWING_PAGE,
};

export default {
  name: 'ShowingTypeSelection',
  components: { SdPage, SdLoadingLayer },
  setup(props, context) {
    const store = context.root.$store;
    const route = context.root.$route;
    const router = context.root.$router;

    const id = route.params.id;
    const unitId = route.params.unit_id;
    const business = store.getters['Auth/business'];

    const addPublishListingShowingProfileRoute = {
      name: showingRoutesMapping[Showing.TYPE_PUBLISH_LISTING],
      params: {
        id,
        unit_id: unitId,
      },
    };

    const uiFlags = reactive({
      codeBoxOptionUnavailable: false,
      allInUse: false,
      isPublishListingEnabled: false,
      isCodeboxConnected: false,
      isSelfShowingEnabled: isBusinessSelfShowingsEnabled(business),
    });

    return {
      init,
      pickShowing,
      uiFlags,
      Showing,
      addPublishListingShowingProfileRoute,
    };

    function pickShowing(showingType) {
      router.push({
        name: showingRoutesMapping[showingType],
        params: {
          id,
          unit_id: unitId,
        },
      });
    }

    async function init() {
      const isCodeBoxConnected = business?.device_connected;
      uiFlags.isPublishListingEnabled = Boolean(business.publish_listing_showing_type_enabled_at);

      if (business.device_connected) {
        let numAvailableCodeboxes = 0;
        let numBookedCodeboxes = 0;
        const devicesData = await store.dispatch(
            'Device/get',
        );
        devicesData.forEach((device) => {
          const available = get(device.showing_profile, 'deleted_at', true) && device.status !== DEVICE_STATUS.IN_USE;
          if (available) {
            ++numAvailableCodeboxes;
          } else {
            ++numBookedCodeboxes;
          }
        });

        uiFlags.codeBoxOptionUnavailable = isCodeBoxConnected && !numAvailableCodeboxes && numBookedCodeboxes > 0;
        uiFlags.isCodeboxConnected = isCodeBoxConnected;
        uiFlags.allInUse = !numAvailableCodeboxes && numBookedCodeboxes > 0;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.showing-type-card-pick {
  position: relative;
  height: 100%;
  display: grid;

  &:hover, &:active, &.selected {
    cursor: pointer;

    .content {
      border-color: theme-color();
      background-color: rgba(theme-color(), 0.03);

      .sd-art {
        background-position: bottom left;
      }
    }
  }

  .content {
    position: relative;
    @media (max-width: 540px) {
      min-height: 276px;
    }
    min-height: 280px;

    &.unavailable {
      min-height: 340px;
    }

    padding: 2rem;
    border: 1px solid gray-color('light');
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

}
</style>
